import http from '../clients/Axios'

export default class SalesmanService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/salesman/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    const res = await http.get(`/salesman${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/salesman', form)
    return res
  }

  async update(form) {
    const res = await http.post(`/salesman/${form.id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/salesman/${id}`)
    return res
  }

  async upload(id, foto, ktp) {
    let formData = new FormData()

    if (foto) {
      formData.append('foto', foto)
    }

    if (ktp) {
      formData.append('ktp', ktp)
    }

    const res = await http.post(`/salesman/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return res
  }

  async export(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    return http({
      url: `/salesman/export?sort=${sort}${filters_param}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
